body {
  box-sizing: none;
  border: none;
  margin: 0;
  background-color: rgb(22, 22, 22);
  font-family: "Roboto", italic;
}

.nav-container {
  width: 80%;
  padding: 20px 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-container .logo img {
  width: 60px;
  cursor: pointer;
  transition: 1s linear;
}
.nav-container .links {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-container .links a {
  text-decoration: none;
  color: rgb(140, 139, 139);
  font-weight: 300;
  font-size: 20px;
}
.nav-container .links a:hover {
  -webkit-text-decoration: underline 2px solid rgb(140, 139, 139);
  text-decoration: underline 2px solid rgb(140, 139, 139);
}
.nav-container .toggle-icon {
  display: none;
}
@media (max-width: 768px) {
  .nav-container {
    position: relative;
    transition: 0.5s all ease-in-out;
    margin-bottom: 100px;
  }

  .nav-container.expanded {
    height: 100vh;
  }

  .nav-container.expanded .links {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .nav-container.expanded .links li {
    margin: 15px 0;
    font-size: 24px;
  }

  .nav-container .logo {
    position: absolute;
    top: 23px;
    left: 20px;
  }

  .nav-container .toggle {
    display: block;

    position: absolute;
    top: 28px;
    right: 20px;
  }
  .icon {
    cursor: pointer;
    color: rgb(140, 139, 139);
    font-size: 50px;
  }

  .nav-container .links {
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .nav-container .links li {
    margin: 15px 0;
    font-size: 24px;
  }
}
.container {
  width: 95%;
  margin: auto;
}
@media screen and (max-width: 580px) {
  .container {
    width: 90%;
  }
}
.row div {
  background-color: rgb(38, 40, 43);
  border-radius: 15px;
}

.row-1 div {
  margin: 4px;
}

.row-2 div {
  margin: 8px;
}

.about {
  display: flex;
  flex-direction: row;
}
.about img {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  margin: 30px 50px;
}
.about .left {
  width: 80%;
}
.about .left .info {
  margin-left: 60px;
  color: rgb(140, 139, 139);
}
.about .left .info .intro {
  margin-bottom: -10px;
  font-size: 1.7em;
}
.about .left .info .intro span {
  color: rgb(63, 130, 170);
  font-weight: bold;
}
.about .left .info p {
  font-size: 20px;
}
.about .right {
  width: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding-bottom: 20px;
}
.about .right .icon {
  color: rgb(63, 130, 170);
  font-size: 50px;
  cursor: pointer;
  transition: 0.5s linear;
}
.about .right .icon:hover {
  color: #def2f1;
  transition: 0.5s linear;
}

.contact-container,
.github-container,
.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.contact-container .contact-icon,
.contact-container .project-icon,
.github-container .contact-icon,
.github-container .project-icon,
.project-container .contact-icon,
.project-container .project-icon {
  color: rgb(140, 139, 139);
  font-size: 60px;
  width: 90%;
  margin-top: 10px;
}
.contact-container .github-icon,
.github-container .github-icon,
.project-container .github-icon {
  font-size: 60px;
  width: 90%;
  color: rgb(140, 139, 139);
  display: flex;
  align-items: start;
  justify-content: start;
}
.contact-container .icon,
.github-container .icon,
.project-container .icon {
  transition: 0.5s linear;
  cursor: pointer;
  color: rgb(140, 139, 139);
}
.contact-container .icon:hover,
.github-container .icon:hover,
.project-container .icon:hover {
  color: rgb(63, 130, 170);
  transition: 0.5s linear;
}
.contact-container .contact-text,
.contact-container .github-text,
.contact-container .project-text,
.github-container .contact-text,
.github-container .github-text,
.github-container .project-text,
.project-container .contact-text,
.project-container .github-text,
.project-container .project-text {
  font-size: 1.7em;
  color: rgb(140, 139, 139);
  width: 95%;
  text-align: left;
}
.contact-container .github-text a,
.contact-container .contact-text a,
.contact-container .project-text a,
.github-container .github-text a,
.github-container .contact-text a,
.github-container .project-text a,
.project-container .github-text a,
.project-container .contact-text a,
.project-container .project-text a {
  text-decoration: none;
  color: rgb(140, 139, 139);
}
.contact-container .project-text,
.github-container .project-text,
.project-container .project-text {
  height: 20%;
}

.tech {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.tech .tech-header {
  font-size: 1.7em;
  text-align: left;
  color: rgb(140, 139, 139);
  width: 100%;
}

.tech-icons {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 90px;
  color: rgb(140, 139, 139);
}
.tech-icons .tech-icon {
  transition: 0.5s linear;
  cursor: pointer;
  padding: 10px;
}
.tech-icons .tech-icon:hover {
  transition: 0.5s linear;
}
.tech-icons .react-icon:hover {
  color: rgb(74, 201, 242);
}
.tech-icons .mongo-icon:hover {
  color: rgb(56, 141, 40);
}
.tech-icons .node-icon:hover {
  color: rgb(111, 192, 31);
}
.tech-icons .bootstrap-icon:hover {
  color: rgb(81, 0, 217);
}
.tech-icons .sass-icon:hover {
  color: rgb(187, 72, 131);
}
.tech-icons .typescript-icon:hover {
  color: rgb(36, 91, 172);
}
.tech-icons .java-script-icon:hover {
  color: rgb(244, 210, 11);
}
.tech-icons .python-icon:hover {
  color: rgb(242, 212, 77);
}
.tech-icons .java-icon:hover {
  color: rgb(200, 34, 15);
}
.tech-icons .cpp-icon:hover {
  color: rgb(30, 102, 187);
}

.nav {
  margin-bottom: 100px;
}

.main-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex;
  margin: auto;
  background-color: rgb(38, 40, 43);
  border-radius: 15px;
}
@media (max-width: 632px) {
  .main-content {
    width: 90%;
  }
  .tech-container {
    width: 90% !important;
  }
}
.main-content .about-header {
  padding-left: 15px;
}
.main-content .about-header h5 {
  font-size: 50px;
  color: rgb(140, 139, 139);
}
.main-content .about-header h5 span {
  color: rgb(63, 130, 170);
}
.main-content .bar {
  margin-top: -10px;
  width: 50%;
  height: 5px;
  background-color: rgb(63, 130, 170);
}
.main-content .text-container {
  color: rgb(140, 139, 139);
  font-size: 1em;
  margin: 15px 15px;
}
.main-content .text-container a {
  -webkit-text-decoration: underline 2px solid rgb(63, 130, 170);
  text-decoration: underline 2px solid rgb(63, 130, 170);
  color: rgb(140, 139, 139);
}

.tech-container {
  background-color: rgb(38, 40, 43);
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-radius: 15px;
}
.tech-container .tech-title {
  margin-top: 5px;
  color: rgb(140, 139, 139);
  font-size: 1.7em;
}

.footer {
  width: 100%;
  text-align: center;
  margin-top: auto;
}
.footer .footer-icons a {
  font-size: 1.7em;
  margin: 10px;
  text-decoration: none;
  color: rgb(140, 139, 139);
}
.footer .footer-text {
  color: rgb(140, 139, 139);
}

.project-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 3em;
  color: rgb(63, 130, 170);
}
.project-content .title {
  -webkit-text-decoration: underline 3px solid rgb(140, 139, 139);
  text-decoration: underline 3px solid rgb(140, 139, 139);
}

.projects {
  display: flex;
  align-items: center;
  font-size: 1.7em;
  width: 90%;
}

.projects-name {
  margin-top: 50px;
  width: 30%;
  cursor: pointer;
}

.projects-info {
  border: 3px solid rgb(140, 139, 139);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
  border-radius: 15px;
  padding: 10px;
  width: 50%;
}
.projects-info h1 {
  font-size: 25px !important;
  color: rgb(140, 139, 139);
}
.projects-info .projects-links {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects-info .projects-links a {
  text-decoration: none;
  font-size: 20px;
  margin: 5px;
  padding: 5px 10px;
  background-color: rgb(38, 40, 43);
  border-radius: 5px;
  color: rgb(63, 130, 170);
  cursor: pointer;
  transition: 0.5s linear;
}
.projects-info .projects-links a:hover {
  background-color: rgb(140, 139, 139);
}

.active {
  color: rgb(63, 130, 170);
}

.inactive {
  color: rgba(140, 139, 139, 0.6);
}

.inactive:hover {
  color: rgb(140, 139, 139);
  transition: 0.3s linear;
} /*# sourceMappingURL=styles.css.map */
@media screen and (max-width: 1332px) {
  .projects {
    justify-content: space-around;
    width: 95%;
  }
}
@media screen and (max-width: 768px) {
  .sm-projects {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .projects-name {
    width: 90%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .projects-title {
    background-color: #333;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 10px;
  }
}
